
import { defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmTabs from '@/components/shared/TmTabs.vue'

export default defineComponent({
  name: 'ServicesEmailLookup',
  components: {
    TmTabs,
    PageContent,
  },
  setup() {
    const currentRoute = ref('')
    const tabs = [
      { name: 'Single lookup', to: { name: 'base.services.emailLookup' } },
      { name: 'Bulk lookup', to: { name: 'base.services.emailLookup.bulkLookup' } },
      { name: 'History', to: { name: 'base.services.emailLookup.history' } },
    ]

    return {
      currentRoute,
      tabs,
    }
  },
})
